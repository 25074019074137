import {useEffect} from 'react'
import {useHistory} from 'react-router-dom'

export const NoMatchView = (val = true) => {
    const history = useHistory()

    useEffect(() => {
      let isMounted = true
      if(isMounted && val){
        history.replace("/")
      }
    
      return () => {
        isMounted = false
      }
       // eslint-disable-next-line
    }, [])
    
  return (
    <>
      {!val &&
      <div className="container d-flex justify-content-center align-items-center vh-100">
          <div className="card">
              <div className="text-guinda text-center">{!val ? '' : 'Error 404' }</div>
          </div> 
      </div>
      }
    </>
    
  )
}
