import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconUI from "./ui/icon_ui";
import HomeIcon from "@mui/icons-material/Home";
import MUIDataTable from "mui-datatables";
import Modal from "@mui/material/Modal";
// iconos
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import InfoIcon from "@mui/icons-material/Info";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import EventIcon from "@mui/icons-material/Event";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import MessageIcon from "@mui/icons-material/Message";
import CancelIcon from "@mui/icons-material/Cancel";
import { services } from "../services/api";
import GifLoader from "./ui/gifLoader";
import dayjs from "dayjs";
import { Button, TextField } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import CategoryIcon from "@mui/icons-material/Category";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import KeyIcon from "@mui/icons-material/Key";
import PasswordIcon from "@mui/icons-material/Password";
import LockIcon from "@mui/icons-material/Lock";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Bitacora = () => {
  const [isLoading, setLoading] = useState(false);

  let date = new Date();
  let ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const [filtros, setFiltros] = useState({
    fechaI: dayjs().format("YYYY-MM-01"),
    fechaF: dayjs().format(`YYYY-MM-${ultimoDia.getDate()}`),
  });

  const handleFiltros = ({ target }) => {
    const { name, value } = target;
    setFiltros({ ...filtros, [name]: value });
  };

  const options = {
    selectableRows: "none",
    tableBodyHeight: "auto",
    // Metodo para descargar CSV con utf-8 para los caracteres especiales
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    textLabels: {
      body: {
        // noMatch: isLoading ? <Loader /> : "Lo sentimos, no hay coincidencias en la busqueda",
        noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
        toolTip: "Clasificar",
        columnHeaderTooltip: (column) => `Clasificar por ${column.label}`,
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtrar tabla",
      },
      filter: {
        all: "Todo",
        title: "Filtros",
        reset: "Restaurar",
      },
      viewColumns: {
        title: "Ver columnas",
        titleAria: "Mostrar/Ocultar columnas en la tabla",
      },
      selectedRows: {
        text: "columna(s) selecciona(as)",
        delete: "Eliminar",
        deleteAria: "Eliminar filas seleccionadas",
      },
    },
    responsive: "standard",
    download: true,
    print: false,
    filter: false,
    viewColumns: false,

    downloadOptions: {
      filename: "Bitacora.csv",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
      utf8WithBom: true,
    },
  };

  const [state, setState] = useState({
    estatus: 0,
    buscar: "Curp, linea de captura, importe, etc.",
    year: 0,
    yeardate: "",
    periodo: "",
    showmodal: false,
    dataApiBitacora: [],
  });

  const [stateModal, setStateModal] = useState({
    importModal: "",
    usuarioCanje: "",
    categoria: "",
    claveDependencia: "",
    claveOficina: "",
    ConceptoModal: "",
    EstatusModal: "",
    FcanjeModal: "",
    fechaGeneracion: "",
    FpagoModal: "",
    FvencimientoModal: "",
    idLinea: "",
    LineaCapturaModal: "",
    nombreDependencia: "",
    usuarioCaptura: "",
  });

  const getBitacora = async () => {
    setLoading(true);

    const body = {
      fechaInicio: filtros.fechaI,
      fechaFin: filtros.fechaF,
    };
    let dataBitacora = await services("POST", `bitacora/listar`, body);

    if (dataBitacora.status === 200) {
      setLoading(false);
      setState({ ...state, dataApiBitacora: dataBitacora.data });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getBitacora();
    }

    return () => {
      isMounted = false;
    };
  }, []);
  const colorletras = "#6f1d46";

  // const handleChangeyeardate = (event) => {
  //   setState({ ...state, yeardate: event.target.value });
  // };
  // const handleChangeyear = (event) => {
  //   setState({ ...state, year: event.target.value });
  // };

  // const handleChangeEstatus = (event) => {
  //   setState({ ...state, estatus: event.target.value });
  // };

  // const handleChangeBusqueda = (event) => {
  //   setState({ ...state, buscar: event.target.value });
  // };

  // const handleChangePeriodo = (event) => {
  //   setState({ ...state, periodo: event.target.value });
  // };
  const openModal = (value) => {
    setState({ ...state, showmodal: true });
    setStateModal({
      ...stateModal,
      importModal: value.Importe === null ? "N/A" : value.Importe,
      usuarioCanje: value.UsuarioCanje === null ? "N/A" : value.UsuarioCanje,
      categoria: value.categoria === null ? "N/A" : value.categoria,
      claveDependencia:
        value.claveDependencia === null ? "N/A" : value.claveDependencia,
      claveOficina: value.claveOficina === null ? "N/A" : value.claveOficina,
      ConceptoModal: value.concepto === null ? "N/A" : value.concepto,
      EstatusModal: value.estatus === null ? "N/A" : value.estatus,
      FcanjeModal: value.fechaCanje === null ? "N/A" : value.fechaCanje,
      fechaGeneracion:
        value.fechaGeneracion === null ? "N/A" : value.fechaGeneracion,
      FpagoModal: value.fechaPago === null ? "N/A" : value.fechaPago,
      FvencimientoModal:
        value.fechaVencimiento === null ? "N/A" : value.fechaVencimiento,
      idLinea: value.id === null ? "N/A" : value.id,
      LineaCapturaModal: value.lcaptura === null ? "N/A" : value.lcaptura,
      nombreDependencia:
        value.nombreDependencia === null ? "N/A" : value.nombreDependencia,
      usuarioCaptura:
        value.usuarioCaptura === null ? "N/A" : value.usuarioCaptura,
      // PeriodoModal:'',
      // CurpModal:value,
    });
  };
  const closeModal = () => {
    setState({ ...state, showmodal: false });
  };

  // const onSearch = () => {
  //   return false;
  // };

  const columns = [
    // ESTATUS
    {
      name: "estatus",
      label: "Estatus",
      align: "center",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex, i) => {
          let estatus = state.dataApiBitacora[dataIndex].estatus;
          return (
            <div
              style={{
                height: "100px",
                borderRight: "1px solid rgb(172 172 172)",
              }}
              className={`px-2 ${
                i % 2 === 0
                  ? "bg-grisClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }`}
            >
              {estatus}
            </div>
          );
        },
      },
    },
    // LINEA DE CAPTURA
    {
      name: "lcaptura",
      label: "Línea de captura",
      align: "center",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex, i) => {
          let Lineadecaptura = state.dataApiBitacora[dataIndex].lcaptura;
          return (
            <div
              style={{
                height: "100px",
                borderRight: "1px solid rgb(172 172 172)",
              }}
              className={`px-3 ${
                i % 2 === 0
                  ? "bg-grisClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }`}
            >
              {Lineadecaptura}
            </div>
          );
        },
      },
    },
    // IMPORTE
    {
      name: "Importe",
      label: "Importe",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex, i) => {
          let importe = state.dataApiBitacora[dataIndex].Importe;
          return (
            <div
              style={{
                height: "100px",
                borderRight: "1px solid rgb(172 172 172)",
              }}
              className={
                i % 2 === 0
                  ? "bg-grisClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }
            >
              $ {importe}
            </div>
          );
        },
      },
    },
    // CONCEPTO
    {
      name: "concepto",
      label: "Concepto",
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let Concepto = state.dataApiBitacora[dataIndex].concepto;
          return (
            <div
              style={{
                height: "100px",
                overflowX: "hidden",
                borderRight: "1px solid rgb(172 172 172)",
              }}
              className={`px-2 ${
                i % 2 === 0
                  ? "bg-grisClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }`}
            >
              {Concepto}
            </div>
          );
        },
      },
    },
    // USUARIO CANJE
    {
      name: "UsuarioCanje",
      label: "Usuario Canje",
      options: {
        // display: false,
        // download: true,
        filter: true,
        sort: false,

        customBodyRenderLite: (dataIndex, i) => {
          let usuarioCanje = state.dataApiBitacora[dataIndex].UsuarioCanje;
          return (
            <div
              style={{
                height: "100px",
                overflowX: "hidden",
                borderRight: "1px solid rgb(172 172 172)",
              }}
              className={`px-2 ${
                i % 2 === 0
                  ? "bg-grisClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }`}
            >
              {usuarioCanje === null ? "N/A" : usuarioCanje}
            </div>
          );
        },
      },
    },
    // CATEGORIA
    {
      name: "categoria",
      label: "Categoría",
      options: {
        // display: false,
        // download: true,
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let categoria = state.dataApiBitacora[dataIndex].categoria;
          return (
            <div
              style={{
                height: "100px",
                overflowX: "hidden",
                borderRight: "1px solid rgb(172 172 172)",
              }}
              className={`px-2 ${
                i % 2 === 0
                  ? "bg-grisClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }`}
            >
              {categoria === null ? "N/A" : categoria}
            </div>
          );
        },
      },
    },
    // CLAVE DEPENDENCIA
    {
      name: "claveDependencia",
      label: "Clave dependencia",
      options: {
        // display: false,
        // download: true,
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let claveDependencia =
            state.dataApiBitacora[dataIndex].claveDependencia;
          return (
            <div
              style={{
                height: "100px",
                overflowX: "hidden",
                borderRight: "1px solid rgb(172 172 172)",
              }}
              className={`px-2 ${
                i % 2 === 0
                  ? "bg-grisClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }`}
            >
              {claveDependencia === null ? "N/A" : claveDependencia}
            </div>
          );
        },
      },
    },
    // CLAVE OFICINA
    {
      name: "claveOficina",
      label: "Clave oficina",
      options: {
        // display: false,
        // download: true,
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let claveOficina = state.dataApiBitacora[dataIndex].claveOficina;
          return (
            <div
              style={{
                height: "100px",
                overflowX: "hidden",
                borderRight: "1px solid rgb(172 172 172)",
              }}
              className={`px-2 ${
                i % 2 === 0
                  ? "bg-grisClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }`}
            >
              {claveOficina === null ? "N/A" : claveOficina}
            </div>
          );
        },
      },
    },
    // FECHA CANJE
    {
      name: "fechaCanje",
      label: "Fecha de canje",
      options: {
        // display: false,
        // download: true,
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let fechaCanje = state.dataApiBitacora[dataIndex].fechaCanje;
          return (
            <div
              style={{
                height: "100px",
                overflowX: "hidden",
                borderRight: "1px solid rgb(172 172 172)",
              }}
              className={`px-2 ${
                i % 2 === 0
                  ? "bg-grisClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }`}
            >
              {fechaCanje === null ? "N/A" : fechaCanje}
            </div>
          );
        },
      },
    },
    // FECHA GENERACION
    {
      name: "fechaGeneracion",
      label: "Fecha generación",
      options: {
        // display: false,
        // download: true,
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let fechaGeneracion =
            state.dataApiBitacora[dataIndex].fechaGeneracion;
          return (
            <div
              style={{
                height: "100px",
                overflowX: "hidden",
                borderRight: "1px solid rgb(172 172 172)",
              }}
              className={`px-2 ${
                i % 2 === 0
                  ? "bg-grisClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }`}
            >
              {fechaGeneracion === null ? "N/A" : fechaGeneracion}
            </div>
          );
        },
      },
    },
    // FECHA DE PAGO
    {
      name: "fechaPago",
      label: "Fecha de pago",
      options: {
        // display: false,
        download: true,
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let fechaPago = state.dataApiBitacora[dataIndex].fechaPago;
          return (
            <div
              style={{
                height: "100px",
                overflowX: "hidden",
                borderRight: "1px solid rgb(172 172 172)",
              }}
              className={`px-2 ${
                i % 2 === 0
                  ? "bg-grisClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }`}
            >
              {fechaPago === null ? "N/A" : fechaPago}
            </div>
          );
        },
      },
    },
    // FECHA DE VENCIMIENTO
    {
      name: "fechaVencimiento",
      label: "Fecha de vencimiento",
      options: {
        // display: false,
        // download: true,
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let fechaVencimiento =
            state.dataApiBitacora[dataIndex].fechaVencimiento;
          return (
            <div
              style={{
                height: "100px",
                overflowX: "hidden",
                borderRight: "1px solid rgb(172 172 172)",
              }}
              className={`px-2 ${
                i % 2 === 0
                  ? "bg-grisClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }`}
            >
              {fechaVencimiento === null ? "N/A" : fechaVencimiento}
            </div>
          );
        },
      },
    },
    // ID
    {
      name: "id",
      label: "ID",
      options: {
        // display: false,
        // download: true,
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let id = state.dataApiBitacora[dataIndex].id;
          return (
            <div
              style={{
                height: "100px",
                overflowX: "hidden",
                borderRight: "1px solid rgb(172 172 172)",
              }}
              className={`px-2 ${
                i % 2 === 0
                  ? "bg-grisClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }`}
            >
              {id === null ? "N/A" : id}
            </div>
          );
        },
      },
    },
    // NOMBRE DEPENDENCIA
    {
      name: "nombreDependencia",
      label: "Nombre dependencia",
      options: {
        // display: false,
        // download: true,
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let nombreDependencia =
            state.dataApiBitacora[dataIndex].nombreDependencia;
          return (
            <div
              clas
              style={{
                height: "100px",
                overflowX: "hidden",
                borderRight: "1px solid rgb(172 172 172)",
              }}
              className={`px-2 ${
                i % 2 === 0
                  ? "bg-grisClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }`}
            >
              {nombreDependencia === null ? "N/A" : nombreDependencia}
            </div>
          );
        },
      },
    },
    // USUARIO CAPTURA
    {
      name: "usuarioCaptura",
      label: "Usuario captura",
      options: {
        // display: false,
        // download: true,
        filter: true,
        sort: false,
        customBodyRenderLite: (dataIndex, i) => {
          let usuarioCaptura = state.dataApiBitacora[dataIndex].usuarioCaptura;

          return (
            <div
              style={{
                height: "100px",
                overflowX: "hidden",
                borderRight: "1px solid rgb(172 172 172)",
              }}
              className={`px-2 ${
                i % 2 === 0
                  ? "bg-grisClaro d-flex align-items-center justify-content-center"
                  : "d-flex align-items-center justify-content-center"
              }`}
            >
              {usuarioCaptura === null ? "N/A" : usuarioCaptura}
            </div>
          );
        },
      },
    },

    {
      name: "Vermasinfo",
      label: "Ver más",

      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (dataIndex, i) => {
          // let op = datafake[dataIndex].broma;
          return (
            <>
              <div
                style={{ height: "100px" }}
                className={
                  i % 2 === 0
                    ? "w-100 d-flex justify-content-center p-3 bg-grisClaro"
                    : "w-100 d-flex justify-content-center p-3"
                }
              >
                <div
                  style={{ height: 40, width: 30, zIndex: 0, marginLeft: 5 }}
                  className="cta cta--guindagob  cta--icon icon_btn"
                  onClick={() => {
                    openModal(state.dataApiBitacora[dataIndex]);
                  }}
                >
                  <IconUI size={"12px"} color={"white"}>
                    <InfoIcon sx={{ fontSize: "1rem", stroke: "#993367" }} />
                  </IconUI>
                </div>
              </div>
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      {isLoading ? <GifLoader /> : null}
      <div className="container">
        <Box sx={{ height: "auto", paddingTop: "3%" }}>
          <Box>
            <div className="my-3">
              <div className="border-bottom--guinda text-big mb-2 text-bold">
                Bitácora de movimientos
              </div>
            </div>
            <p
              style={{
                color: colorletras,
                marginLeft: "1%",
                marginBottom: "1rem",
              }}
            >
              <HomeIcon sx={{ fontSize: "1rem", marginBottom: "2px" }} />
              inicio/Pagos diversos/Bitácora
            </p>

            <div
              style={{ columnGap: 15, rowGap: 20 }}
              className="d-flex flex-wrap flex-row justify-content-center m-2 "
            >
              <TextField
                InputLabelProps={{ shrink: true }}
                onChange={handleFiltros}
                value={filtros.fechaI}
                name="fechaI"
                label="Fecha Inicio"
                size="small"
                type="date"
              />
              <TextField
                InputLabelProps={{ shrink: true }}
                onChange={handleFiltros}
                value={filtros.fechaF}
                name="fechaF"
                label="Fecha Fin"
                size="small"
                type="date"
              />
              <Button
                sx={{
                  backgroundColor: "#6f1d46",
                }}
                variant="contained"
                onClick={() => getBitacora()}
              >
                Filtrar
              </Button>
            </div>
          </Box>
        </Box>
      </div>

      <Grid item className="px-5 pt-4">
        <MUIDataTable
          style={{ Overflow: "hidden" }}
          title={"Bitácora"}
          data={state.dataApiBitacora}
          columns={columns}
          options={options}
          // onDownload={false}
        />
      </Grid>

      {state.showmodal ? (
        <>
          <Modal
            open={state.showmodal}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModal}>
              <div
                style={{
                  height: 30,
                  width: 30,
                  zIndex: 0,
                  marginLeft: 5,
                  position: "absolute",
                  top: "2%",
                  left: "94%",
                }}
                className="cta cta--guindagob  cta--icon icon_btn"
                onClick={() => {
                  closeModal();
                }}
              >
                <div>
                  <IconUI size={"12px"} color={"white"}>
                    <CancelIcon sx={{ fontSize: "1rem", stroke: "#993367" }} />
                  </IconUI>
                </div>
              </div>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  color: colorletras,
                  fontWeight: "bold",
                  fontSize: "1.8rem",
                }}
              >
                Detalle de Línea de captura
              </Typography>
              <div className="d-flex">
                <div>
                  <div>
                    <div></div>
                  </div>
                </div>
                <div></div>
              </div>
              <Grid
                container
                spacing={2}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "3%",
                  marginLeft: "-1%",
                }}
              >
                <Grid item xs={6}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      justifyContent: "left",
                      alignItems: "left",
                      paddingTop: "1%",
                    }}
                  >
                    <Grid item xs={3}>
                      <p
                        className="fw-bold"
                        style={{ marginLeft: "2%", marginBottom: "0rem" }}
                      >
                        <AssignmentTurnedInIcon
                          sx={{
                            fontSize: "1rem",
                            marginBottom: "2px",
                            color: colorletras,
                            marginRight: "10px",
                          }}
                        />
                        Estatus:
                      </p>
                    </Grid>
                    <Grid item xs={3} sx={{ color: colorletras }}>
                      {stateModal.EstatusModal}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    sx={{
                      justifyContent: "left",
                      alignItems: "left",
                      paddingTop: "1%",
                    }}
                  >
                    <Grid item xs={4}>
                      <p
                        className="fw-bold"
                        style={{ marginLeft: "2%", marginBottom: "0rem" }}
                      >
                        <AttachMoneyIcon
                          sx={{
                            fontSize: "1rem",
                            marginBottom: "2px",
                            color: colorletras,
                            marginRight: "10px",
                          }}
                        />
                        Importe:
                      </p>
                    </Grid>
                    <Grid
                      className="ps-0"
                      item
                      xs={4}
                      sx={{ color: colorletras }}
                    >
                      ${stateModal.importModal}
                    </Grid>
                  </Grid>
                  {/* <Grid container spacing={2} sx={{ justifyContent: 'left', alignItems: 'left', paddingTop: "1%" }}>
                                    <Grid item xs={6}>
                                        <p style={{ marginLeft: '2%', marginBottom: '0rem' }}><HourglassEmptyIcon sx={{ fontSize: '1rem', marginBottom: '2px', color: colorletras, marginRight: '10px' }} />Periodo</p>
                                    </Grid>
                                    <Grid item xs={3} sx={{ color: colorletras }}>
                                        {stateModal.PeriodoModal}
                                    </Grid>
                                </Grid> */}
                  {/* <Grid container spacing={2} sx={{ justifyContent: 'left', alignItems: 'left', paddingTop: "1%" }}>
                                    <Grid item xs={6}>
                                        <p style={{ marginLeft: '2%', marginBottom: '0rem' }}><BadgeIcon sx={{ fontSize: '1rem', marginBottom: '2px', color: colorletras, marginRight: '10px' }} />CURP</p>

                                    </Grid>
                                    <Grid item xs={3} sx={{ color: colorletras }}>
                                        {stateModal.CurpModal}
                                    </Grid>
                                </Grid> */}
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      justifyContent: "left",
                      alignItems: "left",
                      paddingTop: "1%",
                    }}
                  >
                    <Grid item xs={6}>
                      <p
                        className="fw-bold"
                        style={{ marginLeft: "2%", marginBottom: "0rem" }}
                      >
                        <LocalAtmIcon
                          sx={{
                            fontSize: "1rem",
                            marginBottom: "2px",
                            color: colorletras,
                            marginRight: "10px",
                          }}
                        />
                        Línea de Captura:
                      </p>
                    </Grid>
                    <Grid
                      className="ps-0"
                      item
                      xs={3}
                      sx={{ color: colorletras }}
                    >
                      {stateModal.LineaCapturaModal}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      justifyContent: "left",
                      alignItems: "left",
                      paddingTop: "1%",
                    }}
                  >
                    <Grid item xs={6}>
                      <p
                        className="fw-bold"
                        style={{ marginLeft: "2%", marginBottom: "0rem" }}
                      >
                        <KeyIcon
                          sx={{
                            fontSize: "1rem",
                            marginBottom: "2px",
                            color: colorletras,
                            marginRight: "10px",
                          }}
                        />
                        Clave dependencia:
                      </p>
                    </Grid>
                    <Grid
                      className="ps-0"
                      item
                      xs={3}
                      sx={{ color: colorletras }}
                    >
                      {stateModal.claveDependencia}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      justifyContent: "left",
                      alignItems: "left",
                      paddingTop: "1%",
                    }}
                  >
                    <Grid item xs={6}>
                      <p
                        className="fw-bold"
                        style={{ marginLeft: "2%", marginBottom: "0rem" }}
                      >
                        <PasswordIcon
                          sx={{
                            fontSize: "1rem",
                            marginBottom: "2px",
                            color: colorletras,
                            marginRight: "10px",
                          }}
                        />
                        Clave oficina:
                      </p>
                    </Grid>
                    <Grid
                      className="ps-0"
                      item
                      xs={3}
                      sx={{ color: colorletras }}
                    >
                      {stateModal.claveOficina}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      justifyContent: "left",
                      alignItems: "left",
                      paddingTop: "1%",
                    }}
                  >
                    <Grid item xs={6}>
                      <p
                        className="fw-bold"
                        style={{ marginLeft: "2%", marginBottom: "0rem" }}
                      >
                        <LockIcon
                          sx={{
                            fontSize: "1rem",
                            marginBottom: "2px",
                            color: colorletras,
                            marginRight: "10px",
                          }}
                        />
                        ID:
                      </p>
                    </Grid>
                    <Grid
                      className="ps-0"
                      item
                      xs={3}
                      sx={{ color: colorletras }}
                    >
                      {stateModal.idLinea}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      justifyContent: "left",
                      alignItems: "left",
                      paddingTop: "1%",
                    }}
                  >
                    <Grid item xs={5}>
                      <p
                        className="fw-bold"
                        style={{ marginLeft: "2%", marginBottom: "0rem" }}
                      >
                        <VerifiedUserIcon
                          sx={{
                            fontSize: "1rem",
                            marginBottom: "2px",
                            color: colorletras,
                            marginRight: "10px",
                          }}
                        />
                        Usuario captura:
                      </p>
                    </Grid>
                    <Grid item xs={5} sx={{ color: colorletras }}>
                      {stateModal.usuarioCaptura}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      justifyContent: "left",
                      alignItems: "left",
                      paddingTop: "1%",
                    }}
                  >
                    <Grid item xs={6}>
                      <p
                        className="fw-bold"
                        style={{ marginLeft: "2%", marginBottom: "0rem" }}
                      >
                        <EventBusyIcon
                          sx={{
                            fontSize: "1rem",
                            marginBottom: "2px",
                            color: colorletras,
                            marginRight: "10px",
                          }}
                        />
                        Fecha de Vencimiento:
                      </p>
                    </Grid>
                    <Grid item xs={3} sx={{ color: colorletras }}>
                      {stateModal.FvencimientoModal}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      justifyContent: "left",
                      alignItems: "left",
                      paddingTop: "1%",
                    }}
                  >
                    <Grid item xs={7}>
                      <p
                        className="fw-bold"
                        style={{ marginLeft: "2%", marginBottom: "0rem" }}
                      >
                        <EventIcon
                          sx={{
                            fontSize: "1rem",
                            marginBottom: "2px",
                            color: colorletras,
                            marginRight: "10px",
                          }}
                        />
                        Fecha de Pago:
                      </p>
                    </Grid>
                    <Grid item xs={3} sx={{ color: colorletras }}>
                      {stateModal.FpagoModal}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      justifyContent: "left",
                      alignItems: "left",
                      paddingTop: "1%",
                    }}
                  >
                    <Grid item xs={7}>
                      <p
                        className="fw-bold"
                        style={{ marginLeft: "2%", marginBottom: "0rem" }}
                      >
                        <CalendarMonthIcon
                          sx={{
                            fontSize: "1rem",
                            marginBottom: "2px",
                            color: colorletras,
                            marginRight: "10px",
                          }}
                        />
                        Fecha de Canje:
                      </p>
                    </Grid>
                    <Grid item xs={3} sx={{ color: colorletras }}>
                      {stateModal.FcanjeModal}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      justifyContent: "left",
                      alignItems: "left",
                      paddingTop: "1%",
                    }}
                  >
                    <Grid item xs={7}>
                      <p
                        className="fw-bold"
                        style={{ marginLeft: "2%", marginBottom: "0rem" }}
                      >
                        <PersonIcon
                          sx={{
                            fontSize: "1rem",
                            marginBottom: "2px",
                            color: colorletras,
                            marginRight: "10px",
                          }}
                        />
                        Usuario canje:
                      </p>
                    </Grid>
                    <Grid item xs={3} sx={{ color: colorletras }}>
                      {stateModal.usuarioCanje}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      justifyContent: "left",
                      alignItems: "left",
                      paddingTop: "1%",
                    }}
                  >
                    <Grid item xs={7}>
                      <p
                        className="fw-bold"
                        style={{ marginLeft: "2%", marginBottom: "0rem" }}
                      >
                        <CategoryIcon
                          sx={{
                            fontSize: "1rem",
                            marginBottom: "2px",
                            color: colorletras,
                            marginRight: "10px",
                          }}
                        />
                        Categoría:
                      </p>
                    </Grid>
                    <Grid item xs={3} sx={{ color: colorletras }}>
                      {stateModal.categoria}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      justifyContent: "left",
                      alignItems: "left",
                      paddingTop: "1%",
                    }}
                  >
                    <Grid item xs={7}>
                      <p
                        className="fw-bold"
                        style={{ marginLeft: "2%", marginBottom: "0rem" }}
                      >
                        <NoteAltIcon
                          sx={{
                            fontSize: "1rem",
                            marginBottom: "2px",
                            color: colorletras,
                            marginRight: "10px",
                          }}
                        />
                        Fecha generación:
                      </p>
                    </Grid>
                    <Grid item xs={3} sx={{ color: colorletras }}>
                      {stateModal.fechaGeneracion}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      justifyContent: "left",
                      alignItems: "left",
                      paddingTop: "1%",
                    }}
                  >
                    <Grid item xs={7}>
                      <p
                        className="fw-bold"
                        style={{ marginLeft: "2%", marginBottom: "0rem" }}
                      >
                        <AccountBalanceIcon
                          sx={{
                            fontSize: "1rem",
                            marginBottom: "2px",
                            color: colorletras,
                            marginRight: "10px",
                          }}
                        />
                        Nombre dependencia:
                      </p>
                    </Grid>
                    <Grid item xs={3} sx={{ color: colorletras }}>
                      {stateModal.nombreDependencia}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <p
                className="fw-bold"
                style={{
                  marginLeft: "2%",
                  marginTop: "3%",
                  marginBottom: "0rem",
                }}
              >
                <MessageIcon
                  sx={{
                    fontSize: "1rem",
                    marginBottom: "2px",
                    color: colorletras,
                    marginRight: "10px",
                  }}
                />
                Concepto:
              </p>
              <p
                style={{
                  color: colorletras,
                  marginTop: "3%",
                  marginLeft: "2%",
                }}
              >
                {stateModal.ConceptoModal}
              </p>
            </Box>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Bitacora;
